import request from '@/utils/request'
const baseURL = '/baseapi'
const HouseApi = {
  HouseList: '/admin/house/list',
  HouseInfo: '/admin/house/houseInfo',
  SaveHouse: '/admin/house/saveHouse',
  CheckHouse: '/admin/house/checkHouse',
  Condition: '/admin/house/condition',
  RegionHouse: '/admin/house/regionHouse'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function houseList (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.HouseList,
    method: 'post',
    data: parameter
  })
}

export function houseInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.HouseInfo,
    method: 'post',
    data: parameter
  })
}

export function saveHouse (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.SaveHouse,
    method: 'post',
    data: parameter
  })
}

export function checkHouse (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.CheckHouse,
    method: 'post',
    data: parameter
  })
}

export function houseCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function regionHouse (parameter) {
  return request({
    baseURL: baseURL,
    url: HouseApi.RegionHouse,
    method: 'post',
    data: parameter
  })
}
