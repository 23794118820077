import request from '@/utils/request'
const baseURL = '/baseapi'
const BuildingApi = {
  BuildingList: '/admin/building/list',
  BuildingInfo: '/admin/building/buildingInfo',
  SaveBuilding: '/admin/building/saveBuilding',
  CheckBuilding: '/admin/building/checkBuilding',
  Condition: '/admin/building/condition',
  RegionBuilding: '/admin/building/regionBuilding',
  AllBuildinglist: '/admin/building/allBuildinglist'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function buildingList (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.BuildingList,
    method: 'post',
    data: parameter
  })
}

export function buildingInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.BuildingInfo,
    method: 'post',
    data: parameter
  })
}

export function saveBuilding (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.SaveBuilding,
    method: 'post',
    data: parameter
  })
}

export function checkBuilding (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.CheckBuilding,
    method: 'post',
    data: parameter
  })
}

export function buildingCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function regionBuilding (parameter) {
  return request({
    baseURL: baseURL,
    url: BuildingApi.RegionBuilding,
    method: 'post',
    data: parameter
  })
}

export function allBuildinglist () {
  return request({
    baseURL: baseURL,
    url: BuildingApi.AllBuildinglist,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
