import request from '@/utils/request'
const baseURL = '/baseapi'
const UserApi = {
  UserList: '/admin/user/userList',
  Condition: '/admin/user/condition',
  UserInfo: 'admin/user/userData',
  SaveData: 'admin/user/saveData',
  SetUserPass: 'admin/user/setUserPass',
  ResetPass: 'admin/user/resetPass',
  SetUserStatus: 'admin/user/setAdminUser',
  CurrentUser: 'admin/user/currentUser',
  SaveCurrentUser: 'admin/user/saveCurrentUser',
  SetUserAvatar: 'admin/user/setUserAvatar',
  CheckUser: 'admin/user/checkUser',
  TeamList: 'admin/user/teamList',
  MemberList: 'admin/user/memberList',
  MemberInfo: 'admin/user/memberInfo',
  SaveMember: 'admin/user/saveMember',
  HouseRelation: 'admin/user/houseRelation',
  CheckMember: 'admin/user/checkMember',
  VisitList: 'admin/user/visitList',
  UserRoleList: 'admin/user/userRoleList',
  SaveUserRegionPower: 'admin/user/saveUserRegionPower',
  SearchAdminUser: 'admin/user/searchAdminUser',
  CheckMemberInfo: 'admin/user/checkMemberInfo',
  SearchUser: 'admin/user/searchUser',
  SearchMember: 'admin/user/searchMember',
  GetUserCode: 'admin/user/getUserCode',
  AllMemberList: 'admin/user/allMemberList'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function userList (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.UserList,
    method: 'post',
    data: parameter
  })
}

export function userCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function userData (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.UserInfo,
    method: 'post',
    data: parameter
  })
}

export function saveData (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SaveData,
    method: 'post',
    data: parameter
  })
}

export function setUserPass (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SetUserPass,
    method: 'post',
    data: parameter
  })
}

export function resetPass (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.ResetPass,
    method: 'post',
    data: parameter
  })
}

export function setUserStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SetUserStatus,
    method: 'post',
    data: parameter
  })
}

export function currentUser () {
  return request({
    baseURL: baseURL,
    url: UserApi.CurrentUser,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function saveCurrentUser (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SaveCurrentUser,
    method: 'post',
    data: parameter
  })
}

export function setUserAvatar (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SetUserAvatar,
    method: 'post',
    data: parameter
  })
}

export function checkUserInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.CheckUser,
    method: 'post',
    data: parameter
  })
}

export function memberList (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.MemberList,
    method: 'post',
    data: parameter
  })
}

export function memberInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.MemberInfo,
    method: 'post',
    data: parameter
  })
}

export function saveMember (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SaveMember,
    method: 'post',
    data: parameter
  })
}

export function houseRelation (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.HouseRelation,
    method: 'post',
    data: parameter
  })
}

export function checkMember (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.CheckMember,
    method: 'post',
    data: parameter
  })
}

export function visitList (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.VisitList,
    method: 'post',
    data: parameter
  })
}

export function userRoleList (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.UserRoleList,
    method: 'post',
    data: parameter
  })
}

export function saveUserRegionPower (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SaveUserRegionPower,
    method: 'post',
    data: parameter
  })
}

export function searchAdminUser (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SearchAdminUser,
    method: 'post',
    data: parameter
  })
}

export function checkMemberInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.CheckMemberInfo,
    method: 'post',
    data: parameter
  })
}

export function searchUser (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SearchUser,
    method: 'post',
    data: parameter
  })
}

export function searchMember (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.SearchMember,
    method: 'post',
    data: parameter
  })
}

export function getUserCode (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.GetUserCode,
    method: 'post',
    data: parameter
  })
}

export function allMemberList (parameter) {
  return request({
    baseURL: baseURL,
    url: UserApi.AllMemberList,
    method: 'post',
    data: parameter
  })
}
