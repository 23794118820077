<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form :form="form" v-if="isShowForm">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="手机号"
            :validateStatus="checkParams.telephoneStatus"
            :help="checkParams.telephoneStatusMsg"
          >
            <!--a-input
              placeholder="请输入手机号"
              v-decorator="['telephone', {initialValue: special.telephone, rules: [{required: true, validator: telephoneCheck, validateTrigger: 'change'}]}]"
              :maxLength="11"
              :disabled="special.keyId > 0 ? true : false"
            /-->
            <a-input-search
              placeholder="请输入手机号"
              v-decorator="['telephone', {initialValue: special.telephone, rules: [{required: true, validator: telephoneCheck, validateTrigger: 'change'}]}]"
              :maxLength="11"
              :disabled="disabled"
              @search="onSearch"
              @change="onSearch"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="帐号"
            :validateStatus="checkParams.realNameStatus"
            :help="checkParams.realNameStatusMsg"
          >
            <a-input
              placeholder="请输入帐号"
              :maxLength="30"
              v-decorator="['account', {initialValue: special.account, rules: [{required: true, validator: realNameCheck, validateTrigger: 'change'}]}]"
              :disabled="special.userCenterId > 0 ? true : false"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="用户姓名"
          >
            <a-input
              placeholder="请输入用户姓名"
              :maxLength="30"
              v-model="special.realName"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="昵称"
          >
            <a-input
              placeholder="请输入昵称"
              v-model="special.nickName"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="邮箱"
          >
            <a-input
              placeholder="请输入邮箱"
              v-model="special.email"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="性别"
          >
            <a-radio-group
              v-model="special.gender"
              style="width: 100%"
            >
              <a-radio :value="0">未知</a-radio>
              <a-radio :value="1">男</a-radio>
              <a-radio :value="2">女</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            style="margin-left: 90px; margin-right: 90px;"
          >
            <a-tag color="volcano">注：未分配小区的用户为游客</a-tag>
            <a-table
              :bordered="true"
              :columns="columns"
              :dataSource="data"
              :pagination="false"
              :locale="locale"
              :loading="memberLoading"
            >
              <template slot-scope="text, record" slot="arealist">
                <span v-if="record.editable">
                  <span style="padding-right: 10px;">
                    <a-cascader
                      :allowClear="false"
                      style="width:200px;"
                      :options="regionList[record.key]"
                      placeholder="省-市-区(县)"
                      :field-names="{label: 'label', value: 'value', children: 'children' }"
                      :value="defaultRegion[record.key]"
                      @change="changeRegion($event, record.key)"
                    />
                  </span>
                  <span style="padding-right: 10px;">
                    <a-select
                      style="width:160px;"
                      v-model="queryAreaParam[record.key].communityId"
                      placeholder="请选择小区"
                      @change="changeCommunity($event, record.key)"
                    >
                      <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                      <a-select-option v-for="item in regionCommunity[record.key]" :key="item.keyId" :value="item.id">
                        {{ item.communityName }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span style="padding-right: 10px;">
                    <a-select
                      style="width:160px"
                      v-model="queryAreaParam[record.key].buildingId"
                      placeholder="选择楼宇"
                      @change="changebuilding($event, record.key)"
                    >
                      <a-select-option :key="0" :value="0">选择楼宇</a-select-option>
                      <a-select-option v-for="item in regionBuilding[record.key]" :key="item.keyId" :value="item.id">
                        {{ item.buildingName }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span style="padding-right: 10px;">
                    <a-select
                      style="width:160px;"
                      v-model="queryAreaParam[record.key].unitId"
                      placeholder="选择单元"
                      @change="changeUnit($event, record.key)"
                    >
                      <a-select-option :key="0" :value="0">选择单元</a-select-option>
                      <a-select-option v-for="item in regionUnit[record.key]" :key="item.keyId" :value="item.id">
                        {{ item.unitName }}
                      </a-select-option>
                    </a-select>
                  </span>
                  <span style="padding-right: 10px;">
                    <a-select
                      style="width:160px"
                      v-model="queryAreaParam[record.key].roomId"
                      @change="changeHouse($event, record.key)"
                      placeholder="选择房屋"
                    >
                      <a-select-option :key="0" :value="0">选择房屋</a-select-option>
                      <a-select-option v-for="item in regionHouse[record.key]" :key="item.keyId" :value="item.id">
                        {{ item.houseName }}
                      </a-select-option>
                    </a-select>
                  </span>
                </span>
                <span v-else>{{ record.address }}</span>
              </template>
              <template slot="operation" slot-scope="text, record">
                <template v-if="record.editable">
                  <span>
                    <a @click="cancel(record.key)" :disabled="record.keyId <= 0">取消</a>
                    <a-divider type="vertical" />
                    <a-popconfirm title="是否要此关联吗？" :disabled="saveDisabled || data.length <= 1" @confirm="remove(record.key)">
                      <a :disabled="data.length <= 1">删除</a>
                    </a-popconfirm>
                  </span>
                </template>
                <span v-else>
                  <a @click="toggle(record.key, record.keyId)" :disabled="saveDisabled || !record.isEdit">编辑</a>
                  <a-divider type="vertical" />
                  <a-popconfirm title="是否要此关联吗？" :disabled="saveDisabled || data.length <= 1 || !record.isEdit" @confirm="remove(record.key)">
                    <a :disabled="data.length <= 1 || !record.isEdit">删除</a>
                  </a-popconfirm>
                </span>
              </template>
            </a-table>
            <a-button :disabled="saveDisabled" style="width: 100%; margin-top: 16px; margin-bottom: 8px" type="dashed" icon="plus" @click="newMember">新增区域</a-button>
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              type="primary"
              @click="gotoBack"
              style="margin-right: 10px;"
            >
              返回
            </a-button>
            <a-button
              type="primary"
              @click="saveMemberApi"
              :disabled="saveDisabled"
              :loading="saveLoading"
            >
              提交
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { regionBuilding } from '@/api/building'
import { regionUnit } from '@/api/unit'
import { regionHouse } from '@/api/house'
import { checkMember, checkMemberInfo, houseRelation, memberInfo, saveMember } from '@/api/user'

export default {
  name: 'MemberInfo',
  data () {
    return {
      gotoType: 1,
      form: this.$form.createForm(this, { name: 'adduserFrom' }),
      saveLoading: false,
      saveDisabled: false,
      loading: false,
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      locale: { emptyText: '添加区域' },
      special: {
        gender: 0,
        userCenterId: 0,
        keyId: 0,
        id: '',
        account: ''
      },
      queryParam: {
        memberId: 0
      },
      queryAreaParam: [
        {
          keyId: 0,
          communityId: 0,
          buildingId: 0,
          unitId: 0,
          provinceCode: 0,
          cityCode: 0,
          areaCode: 0,
          roomId: 0
        }
      ],
      checkParams: {
        houseNameStatus: 'success',
        houseNameStatusMsg: null,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        buildingIdStatus: 'success',
        buildingIdStatusMsg: null,
        unitIdStatus: 'success',
        unitIdStatusMsg: null,
        telephoneStatus: 'success',
        telephoneStatusMsg: null,
        realNameStatus: 'success',
        realNameStatusMsg: null
      },
      regionList: [],
      regionListBase: [],
      defaultRegion: [[]],
      addDefaultRegion: [],
      regionCommunity: [[]],
      regionBuilding: [],
      houseList: [],
      regionUnit: [],
      specialList: [],
      regionHouse: [],
      columns: [
        {
          title: '区域',
          dataIndex: 'arealist',
          scopedSlots: { customRender: 'arealist' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      memberLoading: false,
      dataParams: {},
      data: [
        {
          key: 0,
          address: '',
          keyId: 0,
          editable: true,
          arealist: {
            areaCode: 'areaCode',
            communityId: 'communityId',
            buildingId: 'buildingId',
            unitId: 'unitId',
            roomId: 'roomId'
          }
        }
      ],
      isShowForm: true,
      errors: [],
      disabled: false
    }
  },
  created () {
    this.isShowForm = true
    this.regionListApi()
    if (this.$route.query.memberId !== 0 && this.$route.query.memberId !== undefined && this.$route.query.memberId !== '') {
      this.queryParam.memberId = parseInt(this.$route.query.memberId)
    }
    if (this.$route.query.type !== 0 && this.$route.query.type !== undefined && this.$route.query.type !== '') {
      this.gotoType = parseInt(this.$route.query.type)
    }
    if (this.queryParam.memberId > 0) {
      this.memberInfoApi()
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'GridMemberInfo' || val.name === 'GridVisitMemberInfo') {
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
        this.regionListApi()
        if (this.$route.query.memberId !== 0 && this.$route.query.memberId !== undefined && this.$route.query.memberId !== '') {
          this.queryParam.memberId = parseInt(this.$route.query.memberId)
        }
        if (this.$route.query.type !== 0 && this.$route.query.type !== undefined && this.$route.query.type !== '') {
          this.gotoType = parseInt(this.$route.query.type)
        }
        if (this.queryParam.memberId > 0) {
          this.memberInfoApi()
        } else {
          this.special = {
            gender: 0,
            userCenterId: 0,
            keyId: 0,
            id: '',
            account: '',
            telephone: ''
          }
          this.disabled = false
        }
      }
    }
  },
  methods: {
    // handler
    gotoBack () {
      if (this.gotoType === 2) {
        this.$router.push({ path: '/member/visit-list' })
      } else {
        this.$router.push({ path: '/member/member-list' })
      }
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    onSearch (e) {
      const _this = this
      // console.log('onSearch', e.target.value)
      var mobile = e.target.value.trim()
      _this.special.telephone = mobile
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(mobile)) {
        console.log('telephone', e.target.value)
        checkMemberInfo({ telephone: mobile }).then((res) => {
          if (res.errorCode === 0) {
            if (res.result.userCenterId) {
              _this.special = res.result
              if (res.result.keyId !== 0) {
                _this.data = res.result.relationData
                _this.queryAreaParam = res.result.relationCode
              } else {
                // _this.special = res.result
                _this.data = [
                  {
                    key: 0,
                    address: '',
                    keyId: 0,
                    editable: true,
                    arealist: {
                      areaCode: 'areaCode',
                      communityId: 'communityId',
                      buildingId: 'buildingId',
                      unitId: 'unitId',
                      roomId: 'roomId'
                    }
                  }
                ]
                _this.queryAreaParam = [
                  {
                    keyId: 0,
                    communityId: 0,
                    buildingId: 0,
                    unitId: 0,
                    provinceCode: 0,
                    cityCode: 0,
                    areaCode: 0,
                    roomId: 0
                  }
                ]
              }
              console.log('memberInfo', res.result)
            } else {
              /* _this.special = {
                gender: 0,
                userCenterId: 0,
                keyId: 0,
                id: '',
                telephone: ''
              } */
              _this.special.keyId = 0
              _this.special.userCenterId = 0
              _this.special.id = ''
              _this.special.account = ''
              _this.data = [
                {
                  key: 0,
                  address: '',
                  keyId: 0,
                  editable: true,
                  arealist: {
                    areaCode: 'areaCode',
                    communityId: 'communityId',
                    buildingId: 'buildingId',
                    unitId: 'unitId',
                    roomId: 'roomId'
                  }
                }
              ]
              _this.queryAreaParam = [
                {
                  keyId: 0,
                  communityId: 0,
                  buildingId: 0,
                  unitId: 0,
                  provinceCode: 0,
                  cityCode: 0,
                  areaCode: 0,
                  roomId: 0
                }
              ]
            }
          } else {
            _this.$message.error(res.errorMsg)
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    memberInfoApi () {
      const _this = this
      _this.loading = true
      memberInfo(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          _this.special = res.result
          _this.data = res.result.relationData
          _this.queryAreaParam = res.result.relationCode
          console.log('memberInfo', res.result)
          _this.disabled = true
          _this.loading = false
        }
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    saveMemberApi () {
      const _this = this
      _this.dataParams.keyId = _this.special.keyId ? _this.special.keyId : 0
      _this.dataParams.userCenterId = _this.special.userCenterId ? _this.special.userCenterId : 0
      _this.dataParams.realName = _this.special.realName
      _this.dataParams.telephone = _this.special.telephone
      _this.dataParams.nickName = _this.special.nickName
      _this.dataParams.gender = _this.special.gender
      _this.dataParams.email = _this.special.email
      _this.dataParams.account = _this.special.account
      _this.dataParams.id = _this.special.id
      _this.dataParams.queryAreaParam = _this.queryAreaParam

      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.dataParams.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        return false
      }

      if (_this.dataParams.realName === '') {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入用户姓名'
        return false
      } else {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      }

      _this.saveLoading = true
      _this.saveDisabled = true

      checkMember(_this.dataParams).then((resc) => {
        if (resc.errorCode === 0) {
          saveMember(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              console.log(res)
              if (_this.dataParams.keyId > 0) {
                _this.$message.success('更新用户成功')
              } else {
                _this.$message.success('添加用户成功')
              }
              _this.saveLoading = false
              _this.saveDisabled = false
              _this.$router.push({ path: '/member/member-list' })
            } else {
              _this.$message.error(res.errorMsg)
              _this.saveLoading = false
              _this.saveDisabled = false
            }
          }).catch((err) => {
            console.log(err)
            _this.saveLoading = false
            _this.saveDisabled = false
          })
        } else {
          if (resc.errorCode === 1000001) {
            _this.checkParams.telephoneStatus = 'error'
            _this.checkParams.telephoneStatusMsg = resc.errorMsg
          } else {
            _this.checkParams.realNameStatus = 'error'
            _this.checkParams.realNameStatusMsg = resc.errorMsg
          }
          _this.saveLoading = false
          _this.saveDisabled = false
        }
      }).catch((err) => {
        console.log(err)
        _this.saveLoading = false
        _this.saveDisabled = false
      })
    },
    houseRelationApi (id, key) {
      const _this = this
      houseRelation({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          _this.regionList[key] = _this.regionListBase
          _this.regionList = JSON.parse(JSON.stringify(_this.regionList))
          _this.defaultRegion[key] = res.result.data.defaultRegion
          _this.defaultRegion = JSON.parse(JSON.stringify(_this.defaultRegion))
          _this.regionCommunity[key] = res.result.data.regionCommunity
          _this.regionCommunity = JSON.parse(JSON.stringify(_this.regionCommunity))
          _this.regionBuilding[key] = res.result.data.regionBuilding
          _this.regionBuilding = JSON.parse(JSON.stringify(_this.regionBuilding))
          _this.regionUnit[key] = res.result.data.regionUnit
          _this.regionUnit = JSON.parse(JSON.stringify(_this.regionUnit))
          _this.regionHouse[key] = res.result.data.regionHouse
          _this.regionHouse = JSON.parse(JSON.stringify(_this.regionHouse))
          _this.queryAreaParam[key].communityId = res.result.data.defaultCommunityId
          _this.queryAreaParam[key].buildingId = res.result.data.defaultBuildingId
          _this.queryAreaParam[key].unitId = res.result.data.defaultUnitId
          _this.queryAreaParam[key].roomId = res.result.data.defaultRoomId
          _this.queryAreaParam = JSON.parse(JSON.stringify(_this.queryAreaParam))
          const target = this.data.find(item => item.key === key)
          target._originalData = { ...target }
          target.editable = !target.editable
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionListBase = res.result
          _this.regionList[0] = res.result
          _this.regionList = JSON.parse(JSON.stringify(_this.regionList))
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeRegion (e, key) {
      this.defaultRegion[key] = e
      this.queryAreaParam[key].provinceCode = e[0] ? e[0] : 0
      this.queryAreaParam[key].cityCode = e[1] ? e[1] : 0
      this.queryAreaParam[key].areaCode = e[2] ? e[2] : 0
      this.queryAreaParam[key].communityId = 0
      if (this.queryAreaParam[key].areaCode > 0) {
        this.regionCommunityApi(this.queryAreaParam[key].areaCode, key)
      } else {
        this.regionCommunity[key] = []
      }
      this.regionBuilding[key] = []
      this.queryAreaParam[key].buildingId = 0
      this.regionUnit[key] = []
      this.queryAreaParam[key].unitId = 0
      this.regionHouse[key] = []
      this.queryAreaParam[key].roomId = 0
    },
    changeCommunity (e, key) {
      console.log('changeRegion', e, key, this.queryAreaParam[key].communityId)
      this.queryAreaParam[key].communityId = e
      this.regionBuildingApi(this.queryAreaParam[key].communityId, key)
      this.queryAreaParam[key].buildingId = 0
      this.regionUnit[key] = []
      this.queryAreaParam[key].unitId = 0
      this.regionHouse[key] = []
      this.queryAreaParam[key].roomId = 0
    },
    changebuilding (e, key) {
      this.queryAreaParam[key].buildingId = e
      this.regionUnitApi(this.queryAreaParam[key].buildingId, key)
      this.queryAreaParam[key].unitId = 0
      this.regionHouse[key] = []
      this.queryAreaParam[key].roomId = 0
    },
    changeUnit (e, key) {
      this.queryAreaParam[key].unitId = e
      this.regionHouseApi(this.queryAreaParam[key].unitId, key)
      this.queryAreaParam[key].roomId = 0
    },
    changeHouse (e, key) {
      console.log('changeHouse', e, key)
      this.queryAreaParam[key].roomId = e
      this.queryAreaParam = JSON.parse(JSON.stringify(this.queryAreaParam))
    },
    regionCommunityApi (areaCode, key) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionCommunity[key] = res.result.data
          _this.regionCommunity = JSON.parse(JSON.stringify(_this.regionCommunity))
          console.log('regionCommunityApi', _this.regionCommunity[key], key)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionBuildingApi (communityId, key) {
      const _this = this
      regionBuilding({ communityId: communityId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionBuilding[key] = res.result.data
          _this.regionBuilding = JSON.parse(JSON.stringify(_this.regionBuilding))
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionUnitApi (buildingId, key) {
      const _this = this
      regionUnit({ buildingId: buildingId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionUnit[key] = res.result.data
          _this.regionUnit = JSON.parse(JSON.stringify(_this.regionUnit))
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionHouseApi (unitId, key) {
      const _this = this
      regionHouse({ unitId: unitId }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionHouse[key] = res.result.data
          _this.regionHouse = JSON.parse(JSON.stringify(_this.regionHouse))
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    resetCheck () {
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.houseNameStatus = 'success'
      this.checkParams.houseNameStatusMsg = null
      this.checkParams.communityIdStatus = 'success'
      this.checkParams.communityIdStatusMsg = null
      this.checkParams.buildingIdStatus = 'success'
      this.checkParams.buildingIdStatusMsg = null
      this.checkParams.unitIdStatus = 'success'
      this.checkParams.unitIdStatusMsg = null
      this.checkParams.houseNameStatus = 'success'
      this.checkParams.houseNameStatusMsg = null
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.realNameStatus = 'success'
      this.checkParams.realNameStatusMsg = null
    },
    telephoneCheck (rule, value, callback) {
      const _this = this
      _this.special.telephone = value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(value)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        callback(new Error('手机号格式不正确'))
      }
    },
    realNameCheck (rule, value, callback) {
      const _this = this
      _this.special.account = value.trim()
      if (_this.special.account !== '') {
        _this.checkParams.realNameStatus = 'success'
        _this.checkParams.realNameStatusMsg = null
      } else {
        _this.checkParams.realNameStatus = 'error'
        _this.checkParams.realNameStatusMsg = '请输入用户帐号'
        callback(new Error('请输入用户帐号'))
      }
    },
    handleChange (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    remove (key) {
      const _this = this
      const newData = _this.data.filter(item => item.key !== key)
      this.data = newData
      _this.regionList[key] = []
      _this.regionList = JSON.parse(JSON.stringify(_this.regionList))
      _this.defaultRegion[key] = []
      _this.defaultRegion = JSON.parse(JSON.stringify(_this.defaultRegion))
      _this.regionCommunity[key] = []
      _this.regionCommunity = JSON.parse(JSON.stringify(_this.regionCommunity))
      _this.regionBuilding[key] = []
      _this.regionBuilding = JSON.parse(JSON.stringify(_this.regionBuilding))
      _this.regionUnit[key] = []
      _this.regionUnit = JSON.parse(JSON.stringify(_this.regionUnit))
      _this.regionHouse[key] = []
      _this.regionHouse = JSON.parse(JSON.stringify(_this.regionHouse))
      _this.queryAreaParam[key] = []
      _this.queryAreaParam = JSON.parse(JSON.stringify(_this.queryAreaParam))
    },
    newMember () {
      const length = this.data.length
      const key = length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString()
      this.data.push({
        key: key,
        address: '',
        keyId: 0,
        editable: true,
        arealist: {
          areaCode: 'areaCode',
          communityId: 'communityId',
          buildingId: 'buildingId',
          unitId: 'unitId',
          roomId: 'roomId'
        }
      })
      this.queryAreaParam[parseInt(key)] = {
        communityId: 0,
        buildingId: 0,
        unitId: 0,
        areaCode: 0,
        roomId: 0,
        keyId: 0
      }
      this.defaultRegion[parseInt(key)] = []
      this.regionList[parseInt(key)] = this.regionListBase
    },
    toggle (key, id) {
      this.houseRelationApi(id, key)
      /* const target = this.data.find(item => item.key === key)
      target._originalData = { ...target }
      target.editable = !target.editable */
    },
    cancel (key) {
      const target = this.data.find(item => item.key === key)
      Object.keys(target).forEach(key => { target[key] = target._originalData[key] })
      target._originalData = undefined
    },
    getRowByKey (key, newData) {
      const data = this.data
      return (newData || data).find(item => item.key === key)
    }
  }
}
</script>
