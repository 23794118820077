import request from '@/utils/request'
const baseURL = '/baseapi'
const UnitApi = {
  UnitList: '/admin/unit/list',
  UnitInfo: '/admin/unit/unitInfo',
  SaveUnit: '/admin/unit/saveUnit',
  CheckUnit: '/admin/unit/checkUnit',
  Condition: '/admin/unit/condition',
  RegionUnit: '/admin/unit/regionUnit',
  AllUnitlist: '/admin/unit/allUnitlist'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function unitList (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.UnitList,
    method: 'post',
    data: parameter
  })
}

export function unitInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.UnitInfo,
    method: 'post',
    data: parameter
  })
}

export function saveUnit (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.SaveUnit,
    method: 'post',
    data: parameter
  })
}

export function checkUnit (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.CheckUnit,
    method: 'post',
    data: parameter
  })
}

export function unitCondition (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.Condition,
    method: 'post',
    data: parameter
  })
}

export function regionUnit (parameter) {
  return request({
    baseURL: baseURL,
    url: UnitApi.RegionUnit,
    method: 'post',
    data: parameter
  })
}

export function allUnitlist () {
  return request({
    baseURL: baseURL,
    url: UnitApi.AllUnitlist,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
